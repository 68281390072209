import "core-js/stable";

//- Estilos librería Swiper
require("swiper/dist/css/swiper.min.css");

//- Código SASS
require("./styles/index.scss");

//- Código Javascript.
require("./js/biblored.js");
